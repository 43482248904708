<template>
  <div>TT
      <!-- {{time_table}} -->
       <v-checkbox v-model="swap" label="Swap"></v-checkbox>
       <template v-if="swap">
           <v-row>
          <v-col cols="12" sm="4" md="4">
           <v-checkbox v-model="swap_faculty" label="Faculty"></v-checkbox>
          </v-col>
          <v-col cols="12" sm="4" md="4">
           <v-checkbox v-model="swap_room" label="Room"></v-checkbox>
            </v-col>
          <v-col cols="12" sm="4" md="4">
           <v-checkbox v-model="swap_subject" label="Subject"></v-checkbox>
            </v-col>
           </v-row>
       </template>
      <table border="1">
          <tr>
            <th></th>
            <th v-for="s in slot" :key="s.id">
                {{s.name}}
            </th>
          </tr>
        <tr v-for="tt in time_table" :key="tt.id">
            <th>{{tt[0].day.name}}</th>
            <td v-for="slot in tt" :key="slot.id">
                <v-btn :id="slot.id" @click="getData(slot)">{{slot.id}}</v-btn>
            </td>
        </tr>
      </table>

     <template>
  <v-data-table
    :headers="headers"
    :items="desserts"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>My CRUD</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">New Item</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.name" label="Dessert name"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.calories" label="Calories"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.fat" label="Fat (g)"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.carbs" label="Carbs (g)"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.protein" label="Protein (g)"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.action="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        edit
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">Reset</v-btn>
    </template>
  </v-data-table>
</template>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
        time_table:null,
        slot:null,
        swap: false,
        swap_data:[],
        swap_faculty:true,
        swap_room:true,
        swap_subject:true,
        //
        dialog: false,
      headers: [
        {
          text: 'Dessert (100g serving)',
          align: 'left',
          sortable: false,
          value: 'name',
        },
        { text: 'Calories', value: 'calories' },
        { text: 'Fat (g)', value: 'fat' },
        { text: 'Carbs (g)', value: 'carbs' },
        { text: 'Protein (g)', value: 'protein' },
        { text: 'Actions', value: 'action', sortable: false },
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        name: '',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
      defaultItem: {
        name: '',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
    };
  },
  mounted(){
      axios
      .post("/tryNError/tt")
      .then(res => {
          console.log(res.data)
        this.time_table = res.data.tt_list
        this.slot = res.data.slot
      })
      .catch(error => {
        window.console.log(error);
      })
      .finally(() => {
        
      });
  },  
  methods: {
    getData(data){
       // alert(data.slot.name)
        if(this.swap==true)
        {
            if(!this.swap_faculty && !this.swap_room && !this.swap_subject)
            {
                alert("Please select one.")
                return
            }
            if(this.swap_data.length<1){
                this.swap_data.push(data.id) 
                for(var i=0;i<this.swap_data.length;i++)
                    document.getElementById(this.swap_data[i]).style.color="red"
            }
            else if(this.swap_data.length==1){
                //code for swap
                 for(var i=0;i<this.swap_data.length;i++)
                    document.getElementById(this.swap_data[i]).style.color="black"
                this.swap_data = []    
            }
            alert(this.swap_data)
        }
    },
     initialize () {
        this.desserts = [
          {
            name: 'Frozen Yogurt',
            calories: 159,
            fat: 6.0,
            carbs: 24,
            protein: 4.0,
          },
          {
            name: 'Ice cream sandwich',
            calories: 237,
            fat: 9.0,
            carbs: 37,
            protein: 4.3,
          },
          {
            name: 'Eclair',
            calories: 262,
            fat: 16.0,
            carbs: 23,
            protein: 6.0,
          },
          {
            name: 'Cupcake',
            calories: 305,
            fat: 3.7,
            carbs: 67,
            protein: 4.3,
          },
          {
            name: 'Gingerbread',
            calories: 356,
            fat: 16.0,
            carbs: 49,
            protein: 3.9,
          },
          {
            name: 'Jelly bean',
            calories: 375,
            fat: 0.0,
            carbs: 94,
            protein: 0.0,
          },
          {
            name: 'Lollipop',
            calories: 392,
            fat: 0.2,
            carbs: 98,
            protein: 0,
          },
          {
            name: 'Honeycomb',
            calories: 408,
            fat: 3.2,
            carbs: 87,
            protein: 6.5,
          },
          {
            name: 'Donut',
            calories: 452,
            fat: 25.0,
            carbs: 51,
            protein: 4.9,
          },
          {
            name: 'KitKat',
            calories: 518,
            fat: 26.0,
            carbs: 65,
            protein: 7,
          },
        ]
      },

      editItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        const index = this.desserts.indexOf(item)
        confirm('Are you sure you want to delete this item?') && this.desserts.splice(index, 1)
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          this.desserts.push(this.editedItem)
        }
        this.close()
      },
    },
 
  computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    created () {
      this.initialize()
    },

};
</script>
<style>

</style>